import { CardHeading } from "./cardHeading";
import styles from "./visitCard.module.scss";
import clsx from "clsx";
import { Text } from "@components/Text/Text";
import type { IVisit } from "@models/visits";
import { AssigneeList } from "./assigneeList";
import type { ITimeOfDay } from "@models/activities";
import { timeOfDaySchema } from "@models/activities";
import { format } from "@models/date-and-time";

export const VisitCard = ({
  visit,
}: {
  visit: Pick<IVisit, "assignees" | "occurrences" | "status" | "finishedAt"> & {
    start?: Date;
    end?: Date;
    timeOfDay: ITimeOfDay;
  };
}) => {
  const { start, end, timeOfDay } = visit;

  const formattedTimespan = (binding: string) =>
    start !== undefined && end !== undefined
      ? `${format(start, "HH:mm")}${binding}${format(end, "HH:mm")}`
      : null;

  const visitWithAssignedEmployees = {
    ...visit,
    assignees: visit.assignees.filter(({ employee }) => employee),
  };
  const visitHasAssignedEmployees =
    visitWithAssignedEmployees.assignees.length > 0;

  const timeSpanText =
    timeOfDay === timeOfDaySchema.Values.Any
      ? `Någon gång under dagen.`
      : start && end
        ? `Någon gång mellan ${formattedTimespan(" och ")}.`
        : null;

  return (
    <article className={clsx(styles.visitCard)}>
      <CardHeading
        category={"HomeVisit"}
        title="Hembesök"
        formattedTimespan={timeSpanText}
      />
      <Text element="span" weight="medium">
        {visit.occurrences.map((occurrence) => occurrence.title).join(", ")}
      </Text>
      {visitHasAssignedEmployees ? (
        <AssigneeList assignees={visitWithAssignedEmployees.assignees} />
      ) : null}
    </article>
  );
};
